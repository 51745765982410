import React from "react";
import {
    Card, CardBody, CardFooter, CardTitle,
    Flex,
    FlexItem,
    Grid,
    GridItem, LoginMainFooterLinksItem,
    Stack,
    StackItem,
    Title
} from '@patternfly/react-core';
import { big_reverse_logo } from "../Config/Images";
import {ListItemsInfoLinks} from "../Components/ListItemsInfoLinks";
import styles from '../Pages/InfoPages/infopages.module.css'
import {DiazarAlerts} from "../Components/DiazarAlerts";
import {DiazarBackgroundImage} from "../Components/DiazarBackgroundImage";
import {FacebookFIcon, LinkedinIcon, TwitterIcon, YoutubeSquareIcon} from "@patternfly/react-icons";

interface ViewProps {
    children?: React.ReactNode;
    title?: string
}

export const InfoLayout: React.FunctionComponent<ViewProps> = (props) => {
    const socialMediaLoginContent = (
        <React.Fragment>
            <LoginMainFooterLinksItem href="https://www.facebook.com/DiazarControl/"
                                      linkComponentProps={{ 'aria-label': 'Facebook' }}
                                      target="_blank"
            >
                <FacebookFIcon />
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://twitter.com/diazarcontrol"
                                      linkComponentProps={{ 'aria-label': 'Twitter' }}
                                      target="_blank"
            >
                <TwitterIcon size="lg" />
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://www.youtube.com/user/DiazarNoticias"
                                      linkComponentProps={{ 'aria-label': 'Youtube' }}
                                      target="_blank"
            >
                <YoutubeSquareIcon size="lg" />
            </LoginMainFooterLinksItem>
            <LoginMainFooterLinksItem href="https://www.linkedin.com/in/diazar-control-5068323b"
                                      linkComponentProps={{ 'aria-label': 'Linkedin' }}
                                      target="_blank"
            >
                <LinkedinIcon size="lg"/>
            </LoginMainFooterLinksItem>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <DiazarBackgroundImage />
            <DiazarAlerts />
            <Grid className={styles.container}>
                <GridItem span={12}>
                    <Stack hasGutter className={ styles.fullHeight }>
                        <StackItem>
                            <Flex fullWidth={{default:'fullWidth'}} className={styles.marginTop}>
                                <FlexItem>
                                    <img src={big_reverse_logo} alt="Diazar Logo" className={styles.logo}/>
                                </FlexItem>
                                <FlexItem>&nbsp;</FlexItem>
                                <FlexItem>
                                    <Title headingLevel="h2" className={ styles.colorWite } >
                                        { props.title }
                                    </Title>
                                </FlexItem>
                                <FlexItem align={{ default: 'alignRight' }} >
                                    <ListItemsInfoLinks className={ styles.colorWite }/>
                                </FlexItem>
                            </Flex>

                        </StackItem>

                        <StackItem isFilled className="pf-u-box-shadow-sm" >
                            <Card>
                                <CardTitle>{props.title}</CardTitle>
                                <CardBody className={styles.infoContainer}>{ props.children }</CardBody>
                            </Card>
                            x
                        </StackItem>
                        <StackItem>
                            &nbsp;
                        </StackItem>
                    </Stack>
                </GridItem>
            </Grid>

        </React.Fragment>

    )
}
