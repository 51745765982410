import {LoginPayload} from "./AuthProviderReducer";
import React from "react";
import { ServicesUrl } from "../../Config/UrlRuotes";

export interface LoginDispacher{
    type?:"REQUEST_LOGIN" | "LOGIN_SUCCESS" | "LOGIN_ERROR" | "LOGOUT",
    payload?: LoginPayload
    error?:string
}

export interface LoginPayloadRequest {
    company: string,
    username: string,
    password: string,
}

export interface ActionResponse {
    status: "ERROR"|"SUCCESS",
    error: {
        "has-error":boolean,
        error?:string
    },
    response: {}
}

export interface LoginActionResponse extends ActionResponse{
    response: {
        token: string
        user:UserApp
    }
}

export interface UserApp {
    id: number,
    clave: string,
    first_name: string,
    last_name: string,
    user_type: string,
    email: string,
    mail_validado: boolean,
}

export async function updateLoginUser(data:LoginActionResponse, dispatch:React.Dispatch<LoginDispacher>) {
    dispatch({ type: 'LOGIN_SUCCESS', payload: data.response });
    localStorage.setItem('currentUser', JSON.stringify(data.response));
    return data
}

export async function loginUser(loginPayload:LoginPayloadRequest, dispatch:React.Dispatch<LoginDispacher>) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload),
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });
        let response = await fetch(ServicesUrl.login, requestOptions);

        let data : LoginActionResponse = await response.json();

        if( data.status === "ERROR" && data.error["has-error"] ){
            dispatch({ type: 'LOGIN_ERROR', error: data.error.error });
            return;
        }

        if (data.response.token ) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: data.response });
            localStorage.setItem('currentUser', JSON.stringify(data.response));
            return data
        }

        return ;
    } catch (error) {
        let message = "Error al momento de realizar la petición"
        dispatch({ type: 'LOGIN_ERROR', error: message});

        return ;
    }
}

export async function logout(dispatch:React.Dispatch<LoginDispacher>) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}
