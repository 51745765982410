import React from "react";
import {useAuthDispatch, useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {logout} from "../../Context/AuthProvider/AuthProviderActions";
import {LoginLayout} from "../../Layouts/LoginLayout";
import {Button, EmptyState, EmptyStateIcon, EmptyStateSecondaryActions, Title} from "@patternfly/react-core";
import {UserSlashIcon} from "@patternfly/react-icons/dist/esm/icons";
import {useNavigate} from "react-router-dom";

export const ZendDeskLogOut : React.FunctionComponent = (_props) => {
    const {token} = useAuthState()
    const dispatch = useAuthDispatch()

    const queryParameters = new URLSearchParams(window.location.search)
    const email = queryParameters.get("email")
    const kind = queryParameters.get("kind")
    const message = queryParameters.get("message")
    const return_to = queryParameters.get("return_to")

    const navigate = useNavigate()

    React.useEffect( () => {
        if ( token !== undefined && token !== "" ){
            logout(dispatch)
        }
    }, [token, dispatch] );

    const title = React.useMemo( () => {
        switch (kind) {
            case "error":
                return "Error"
            case "info":
                return "Información"
            default:
                return "Cerrando sesión"
        }
    }, [kind] )

    const subTitle = React.useMemo( () => {
        const  messageText = (email !== null) ? `Estimado ${email}` : undefined
        return messageText
    }, [email] )

    const returnToCallBack = React.useCallback( () => {
        if( return_to !== null ){
            window.location.replace(return_to);
        }
    }, [return_to] )

    const loginCallBack = React.useCallback( () => {
        let url = '/zendesk/sso'
        if( return_to !== null ){
            url = `${url}?return_to=${encodeURIComponent(return_to)}`
        }

        navigate(url)
    }, [navigate, return_to] )

    return (
        <LoginLayout loginTitle={title} loginSubtitle={subTitle}>
            <EmptyState>
                <EmptyStateIcon icon={UserSlashIcon} />
                <Title headingLevel="h4" size="lg">
                    { message }
                </Title>
                <EmptyStateSecondaryActions>
                    { return_to !== null ?
                        <Button variant="link" onClick={returnToCallBack}>Regresar</Button> : null
                    }
                    <Button variant="link" onClick={loginCallBack}>Iniciar Sessión</Button>
                </EmptyStateSecondaryActions>
            </EmptyState>
        </LoginLayout>
    )
}
