import React from "react";


import { NotFound } from "../Pages/NotFound/NotFound"
import { TermsOfUse } from "../Pages/InfoPages/TermsOfUse";
import {PrivacyPolicies} from "../Pages/InfoPages/PrivacyPolicies";
import {PasswordResetRequest} from "../Pages/PasswordResetRequest/PasswordResetRequest";
import {RestorePassword} from "../Pages/RestorePassword/RestorePassword";
import {AprobarJWT} from "../Pages/AprobarJWT/AprobarJWT";
import {ZendeskSSO} from "../Pages/ZendDesk/ZendeskSSO";
import {ZendDeskLogOut} from "../Pages/ZendDesk/ZendDeskLogOut";

export interface RoutesType {
    path: string;
    component: React.ComponentType;
    isPrivate: boolean
}

export const routes:RoutesType[] = [
    {
        path:'/terms-of-use',
        component:TermsOfUse,
        isPrivate: false
    },
    {
        path:'/privacy-policies',
        component:PrivacyPolicies,
        isPrivate: false
    },
    {
        path:'/password-reset-request',
        component:PasswordResetRequest,
        isPrivate: false
    },
    {
        path:'/',
        component:PasswordResetRequest,
        isPrivate: false
    },
    {
        path:'/restore_password/:tokenRestore',
        component:RestorePassword,
        isPrivate: false
    },
    {
        path:'/aprobar/:tokenJWT',
        component:AprobarJWT,
        isPrivate: false
    },
    {
        path:'/zendesk/sso',
        component:ZendeskSSO,
        isPrivate: false
    },
    {
        path:'/zendesk/logout',
        component:ZendDeskLogOut,
        isPrivate: false
    },
    {
        path:'/*',
        component:NotFound,
        isPrivate: false
    }
]
