import React from "react";
import {LoginLayout} from "../../Layouts/LoginLayout";
import {useParams} from "react-router-dom";
import {Bullseye, Spinner} from "@patternfly/react-core";
import {PasswordValidTokenPayload, ServicesUrl, UserToResetPass} from "../../Config/UrlRuotes";
import {DiazarRestorePassForm} from "./DiazarRestorePassForm";


export const RestorePassword : React.FunctionComponent = (_props)=>{
    const [isValidToken, setIsValidToken] = React.useState<boolean|undefined>( undefined )
    const [isLoading, setIsLoading] = React.useState<boolean>( true )
    const [message, setMessage] = React.useState<string>( '' )
    const [user, setUser] = React.useState<UserToResetPass|undefined>( undefined )

    const textContent = React.useMemo( () => {
        const text = 'Si usted tiene problemas para iniciar sesión en los sistemas Diazar, puede restablecer ' +
            'reestableser su contraseña. Es neesario que cunete con acceso al correo electrónico y ' +
            'el identificador de la empresa donde dessea reestablecer su contraseña.'

        return text
    }, [])

    let { tokenRestore } = useParams();

    const component = React.useMemo( ()=>{
        if( isValidToken === undefined ){
            const messageAux = 'Validando petición con el servidor'
            return (
                <Bullseye style={ {height:'200px'} }>
                    { (isLoading) ? <Spinner size="lg" isSVG aria-label={ messageAux }/> : null }
                    {messageAux}
                </Bullseye>
            )
        }else if( !isValidToken ){
            return (
                <Bullseye style={ {height:'200px', color:'red'} }>
                    { (isLoading) ? <Spinner size="lg" isSVG aria-label={ message }/> : null }
                    {message}
                </Bullseye>
            )
        }else if( user ){
            return <DiazarRestorePassForm userData={ user } />
        }

        return null
    }, [ isValidToken, isLoading, user, message ] )

    React.useEffect( ()=>{
        if( tokenRestore !== undefined ){
            setIsLoading( true )
            const data = {
                "token":tokenRestore
            }
            fetch( ServicesUrl.validate, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            } )
                .then(response => response.json())
                .then( (data)=>{
                    setIsLoading( false )
                    const response:PasswordValidTokenPayload = data
                    if( response.error["has-error"] ){
                        setIsValidToken( false )
                        setMessage( (response.error.message) ? response.error.message : '' )
                    }else{
                        setUser( response.response )
                        setIsValidToken( true )
                    }
                })
                .catch( error => {
                    setIsLoading( false )
                    console.log( error )
                } )
        }

    }, [tokenRestore] )

    return (
        <React.Fragment>

            <LoginLayout loginTitle="Reestablecer Contraseña"
                         loginSubtitle=""
                         textContent={textContent}
            >
                { component }
            </LoginLayout>

        </React.Fragment>
    );
}
