import {GetToken} from "../Domain";
import {ServicesUrl} from "../../Config/UrlRuotes";

export const resolveAction = ( jwToken: string ) => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.action }/${jwToken}`,
    headers: {
        "Authorization-Token": GetToken()
    }
})
