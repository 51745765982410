import React, {useState} from "react";
import {LoginPayloadRequest, loginUser} from "../../Context/AuthProvider/AuthProviderActions";
import {useAuthDispatch, useAuthState} from "../../Context/AuthProvider/AuthProvider";
import { FormHelperText, ActionGroup, Form, Button} from "@patternfly/react-core";
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import {useAlertDispatcherState} from "../../Context/AlertProvider/AlertProvider";
import {addAlert} from "../../Context/AlertProvider/AlertProviderActions";
import {DiazarFormInput} from "../../Components/Form/DiazarFormInput";


export const LoginForm: React.FunctionComponent = (_props) => {
    const [username, setUserName] = useState<string>("")
    const [isValidUsername, setIsValidUserName] = useState<boolean>(false)

    const [password, setPassword] = useState<string>("")
    const [isValidPassword, setIsValidPassword] = useState<boolean>(false)

    const [company, setCompany] = useState<string>("")
    const [isValidCompany, setIsValidCompany] = useState<boolean>(false)

    const dispatch = useAuthDispatch()
    const alertDispatch = useAlertDispatcherState()

    const { loading, errorMessage } = useAuthState()

    const handleLogin = React.useCallback( async ()=>{
        let payload:LoginPayloadRequest = { company:company, username:username, password:password}

        try {
            let responseLogin = await loginUser(payload, dispatch)
            if (!responseLogin || !responseLogin.response || !responseLogin.response.user || responseLogin.response.user.id <= 0) return

            addAlert('Autentificación exitosa', `Bienvenido ${ responseLogin.response.user.clave }` ,"success", alertDispatch )
        } catch (error) {
            console.log(error)
        }


    }, [company, username, password, dispatch, alertDispatch] )

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        return !isLoading && isValidCompany && isValidUsername && isValidPassword

    }, [isValidCompany, isValidUsername, isValidPassword, loading] )

    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText isError={true} isHidden={false}>
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    const loadingForm = React.useMemo( () => {
        return ( loading !== undefined ) ? loading : false
    }, [loading] )

    return (
        <React.Fragment>
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <Form>
                        { helperText }
                        <DiazarFormInput id="login-clave" label="Clave" isRequired
                                         type="text"
                                         placeholder="Clave de Usuario"
                                         getState={ (isValid, value) => {
                                             setIsValidUserName( isValid )
                                             setUserName( value )
                                         } }
                                         initialValue={ username }
                                         isReadOnly={ loadingForm }
                        />

                        <DiazarFormInput id="login-password" label="Contraseña" isRequired
                                         type="password"
                                         placeholder="Contraseña de Usuario"
                                         getState={ (isValid, value) => {
                                             setIsValidPassword( isValid )
                                             setPassword( value )
                                         } }
                                         initialValue={ password }
                                         isReadOnly={ loadingForm }
                        />

                        <DiazarFormInput id="login-company" label="Empresa" isRequired
                                         type="text"
                                         placeholder="Empresa"
                                         getState={ (isValid, value) => {
                                             setIsValidCompany( isValid )
                                             setCompany( value )
                                         } }

                                         initialValue={ company }
                                         isReadOnly={ loadingForm }
                        />

                        <ActionGroup>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); handleLogin() }}
                                    isBlock
                                    isDisabled={ !isValidForm }
                                    isLoading={ loadingForm }
                            >
                                Enviar
                            </Button>
                        </ActionGroup>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}
