import React from "react";
import {LoginDispacher, UserApp} from "./AuthProviderActions";

let user:UserApp = localStorage.getItem("currentUser")
    ? JSON.parse( localStorage.getItem("currentUser") + "" ).user
    : {};

let token:string = localStorage.getItem("currentUser")
    ? JSON.parse( localStorage.getItem("currentUser") + "" ).token
    : "";

export interface LoginPayload { //HNResponse
    user?:            UserApp
    token?:          string,
    loading?:        boolean,
    errorMessage?:   string
}

export const initialState:LoginPayload = {
    user:         undefined || user,
    token:        "" || token,
    loading:      false,
    errorMessage: undefined
};

export let AuthReducer: React.Reducer<LoginPayload, LoginDispacher> ;
AuthReducer = (initialState: LoginPayload, action: LoginDispacher): LoginPayload => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            let copy : LoginPayload = {...initialState, loading: false, errorMessage:undefined}
            if (action.payload !== undefined ){
                copy.user = action.payload?.user
                copy.token = action.payload?.token
            }

            return copy;
        case "LOGOUT":
            return {
                ...initialState,
                user: undefined,
                token: "",
                errorMessage:undefined
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};