import React from "react";
import {useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {LoginLayout} from "../../Layouts/LoginLayout";
import {LoginForm} from "../AprobarJWT/LoginForm";
import {useMutation, useQuery} from "react-fetching-library";
import {getZendeskToken} from "../../Actions/ZendDeskTokenActions/ZendeskTokenActions";
import {EmptyState, Spinner, Title} from "@patternfly/react-core";
import {ValidateEmailZD} from "./ValidateEmailZD";

export const ZendeskSSO : React.FunctionComponent = (_props) => {
    const loginTitle="Login Requerido";
    const loginSubtitle="Es necesario iniciar sesión para poder continuar.";
    const textContent="Para poder acceder al módulo de Soporte, es necesario que inicie sesión en el sistema.";

    const {token, user} = useAuthState()
    const [sendPetition, setSendPetition] = React.useState<boolean>(false)
    const [hasLogin, setHasLogin] = React.useState<boolean>(false)

    const queryParameters = new URLSearchParams(window.location.search)
    const returnTo = queryParameters.get("return_to")
    // @ts-ignore
    const getTokenZ = useMutation( getZendeskToken );

    const isFormatEmail = React.useMemo( () => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if( user !== undefined && user.email !== undefined ){
            return (user.email.match(validRegex) !== null)
        }
        return false
    }, [user, user?.email] )

    React.useEffect( () => {
        setHasLogin ( token !== undefined && token !== "")
        setSendPetition(true )
    }, [token] );

    React.useEffect( () => {
        if( hasLogin && sendPetition && isFormatEmail && user !== undefined && user.mail_validado ){
            setSendPetition(false)

            getTokenZ.mutate({}).then( (res) => {
                if( res.payload !== undefined ){
                    const payload = res.payload

                    if( payload.error["has-error"] === false ){
                        const response = payload.response
                        let url = `${response.endpoint}?jwt=${response.jwt_token}`

                        if( returnTo !== null ){
                            url = `${url}&return_to=${encodeURIComponent(returnTo)}`
                        }

                        window.location.replace(url);
                    }
                }
            } )
        }
    }, [hasLogin, sendPetition, returnTo, getTokenZ, isFormatEmail, user] );



    if ( hasLogin && user !== undefined ){
        if ( !user.mail_validado || !isFormatEmail ){
            return (
                <LoginLayout loginTitle={loginTitle}
                             loginSubtitle={"Es necesario validar su correo electrónico para poder continuar."}
                             textContent={textContent}
                >
                    <ValidateEmailZD />
                </LoginLayout>
            )
        }

        return (
            <LoginLayout loginTitle={loginTitle}
                         loginSubtitle={loginSubtitle}
                         textContent={textContent}
            >
                <EmptyState>
                    <Spinner />
                    <Title headingLevel="h4" size="lg">
                        Obteniendo Información del Servidor
                    </Title>
                </EmptyState>
            </LoginLayout>
        )
    }


    return (
        <LoginLayout loginTitle={loginTitle}
                     loginSubtitle={loginSubtitle}
                     textContent={textContent}
        >
            <LoginForm />
        </LoginLayout>
    )
}
