import React from "react";
import {DiazarFormInput} from "../../Components/Form/DiazarFormInput";
import {useAuthDispatch, useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {ActionGroup, Button, Form} from "@patternfly/react-core";
import {getEmailValidationCode, sendEmailValidationCode} from "../../Actions/ZendDeskTokenActions/ZendeskTokenActions";
import {QueryResponse, useParameterizedQuery} from "react-fetching-library";
import {BasicResponse} from "../../Actions/Domain";
import {useAlertDispatcherState} from "../../Context/AlertProvider/AlertProvider";
import {addAlert} from "../../Context/AlertProvider/AlertProviderActions";
import {LoginActionResponse, updateLoginUser} from "../../Context/AuthProvider/AuthProviderActions";

export const ValidateEmailZD : React.FunctionComponent = (_props) => {
    const [email, setEmail] = React.useState<string>('')
    const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true)

    const [code, setCode] = React.useState<string>('')
    const [isValidCode, setIsValidCode] = React.useState<boolean>(true)

    const [isSend, setIsSend] = React.useState<boolean>(false)

    const {user} = useAuthState()
    const userDispatch = useAuthDispatch()

    const alertDispatch = useAlertDispatcherState()

    // @ts-ignore
    const getMailCodeRequest = useParameterizedQuery(getEmailValidationCode)

    // @ts-ignore
    const sendMailCodeRequest = useParameterizedQuery(sendEmailValidationCode)

    const isValidForm = React.useMemo<boolean>( () => {
        if( !isSend ){
            return isValidEmail
        }else {
            return isValidCode
        }
    }, [isSend, isValidEmail, isValidCode] )

    const sendValidateEmailRequest = React.useCallback( () => {
        if( isValidForm ){
            getMailCodeRequest.query({email:email}).then( (res: QueryResponse<BasicResponse>) => {
                if ( res.payload ){
                    const payload = res.payload

                    if( payload.error["has-error"] === false && payload.response["message"] !== undefined ){
                        addAlert('Código de Verificación Enviado', payload.response["message"], "success", alertDispatch )
                        setIsSend(true)
                    }else{
                        addAlert('Error al enviar el correo electrónico', payload.error.error, "danger", alertDispatch )
                        console.log( payload.error.error )
                    }
                }
            } )
        }
    }, [isValidForm, email, alertDispatch, getMailCodeRequest] )

    const sendCodeRequest = React.useCallback( () => {
        if (isSend){
            sendMailCodeRequest.query({email:email, code:code}).then( (res: QueryResponse<LoginActionResponse>) => {
                if (res.payload ){
                    const payload = res.payload
                    if (!payload.error["has-error"]){
                        updateLoginUser(payload, userDispatch)
                    }else{
                        addAlert('Error al validar el código', payload.error.error, "danger", alertDispatch )
                    }
                }
            } )
        }
    }, [isSend, sendMailCodeRequest, email, code, userDispatch, alertDispatch] )


    const INPUTS = React.useMemo( () => {
        const inpts: JSX.Element[] = [
            <DiazarFormInput
                id="email"
                key="email"
                label="Correo Electrónico"
                type="email"
                placeholder="Introduzca su correo electrónico"
                isRequired={true}
                getState={ (isValid:boolean, value:string) => {
                    setIsValidEmail(isValid)
                    setEmail(value)
                } }

                initialValue={email.length === 0 ? user?.email : email}
                helperText={ (isSend) ? undefined : 'Al dar click a enviar, se enviará un correo electrónico a la dirección proporcionada para validar su cuenta.'}
                isReadOnly={ isSend }
            />
        ]

        if (isSend){
            inpts.push((
                <DiazarFormInput
                    id="code"
                    key="code"
                    label="Código de verificación"
                    type="number"
                    min={6}
                    max={6}
                    placeholder="Introduzca el código de verificación"
                    isRequired={true}

                    initialValue={code}
                    getState={ (isValid:boolean, value:string) => {
                        setIsValidCode(isValid)
                        setCode(value)
                    } }
                    helperText={ 'Introduzca el código de verificación de 6 digitos que se le envió a su correo electrónico.'}
                />
            ))
        }

        return inpts
    }, [email, user?.email, isSend, code] )

    const BTN = React.useMemo( () => {
        const isLoading = getMailCodeRequest.loading || sendMailCodeRequest.loading

        return (
            <Button variant="primary"
                    type="button"
                    onClick={ e => {
                        e.preventDefault();
                        if(!isSend){
                            sendValidateEmailRequest();
                        }else{
                            sendCodeRequest();
                        }
                    }}
                    isDisabled={ !isValidForm || isLoading }
                    isLoading={ isLoading }
            >
                { !isSend ? 'Generar Código' : 'Validar Código' }
            </Button>
        )
    }, [isValidForm, sendValidateEmailRequest, sendCodeRequest, isSend, getMailCodeRequest, sendMailCodeRequest] )

    return (
        <Form onSubmit={ e => { e.preventDefault(); } } >
            {INPUTS}

            <ActionGroup>
                { BTN }
            </ActionGroup>
        </Form>
    );
}
