const API_URL = '/users_api/password_reset';
const ROOT_URL = '/api';

export const ServicesUrl = {
    login: `${ROOT_URL}/login`,
    action: `${ROOT_URL}/action`,
    zendesk: `${ROOT_URL}/zendesk`,
    request_pass: `${API_URL}/request_action.json`,
    reset_pass: `${API_URL}/reset_action.json`,
    validate: `${API_URL}/validate_token.json`,
}

interface Payload {
    error: {
        "has-error":boolean,
        message?: string
    },
    response: any
}

export interface PasswordResetPayload extends Payload{
    response:{
        message?: string
    }
}

export interface PasswordValidTokenPayload extends Payload{
    response:UserToResetPass
}

export interface UserToResetPass {
    id: number,
    firstNames: string,
    lastNames: string,
    key: string,
    email: string
    companyId: number
    companyName: string
    token: string
    is_internal: boolean
}
