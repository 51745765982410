import React from "react";
import {Button, Modal, ModalVariant} from "@patternfly/react-core";

interface ViewProps {
    message?: string
}

export const ModalResetPasswordSuccess : React.FunctionComponent<ViewProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
    const message = React.useMemo( ()=>{
        return ( props.message ) ? props.message : ''
    }, [ props.message ] )

    const handleModalToggle = React.useCallback( ()=>{
        window.location.reload();
    }, [] )

    React.useEffect( ()=>{
        setIsModalOpen( message.trim() !== '' )
    }, [message] )


    return (

            <Modal
                variant={ModalVariant.medium}
                title="Información:"
                isOpen={isModalOpen}
                onClose={handleModalToggle}
                actions={[
                    <Button key="confirm" variant="primary" onClick={handleModalToggle}>
                        OK
                    </Button>,
                ]}
            >
                { message }
            </Modal>
    )
}