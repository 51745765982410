import React, {useState} from "react";
import {ActionGroup, Form, Button, FormHelperText} from "@patternfly/react-core";
import {DiazarFormInput} from "../../Components/Form/DiazarFormInput";
import {PasswordResetPayload, ServicesUrl} from "../../Config/UrlRuotes";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {ModalResetPasswordSuccess} from "./ModalResetPasswordSuccess";

export const DiazarPasswordResetForm: React.FunctionComponent = (_props) => {
    const [email, setEmail] = useState<string>("")
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false)

    const [company, setCompany] = useState<string>("")
    const [isValidCompany, setIsValidCompany] = useState<boolean>(false)

    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined)
    const [successMessage, setSuccessMessage] = useState<string|undefined>(undefined)


    const handleLogin = React.useCallback( async ()=>{
        const data = {
            email: email,
            company: company
        }

        setLoading( true )

        await fetch( ServicesUrl.request_pass, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        } )
            .then(response => response.json())
            .then( (data)=>{
                setLoading( false )
                const response:PasswordResetPayload = data
                if( response.error["has-error"] ){
                    setErrorMessage( response.error.message )
                }else{
                    setErrorMessage( undefined )
                    if( response.response.message ){
                        setSuccessMessage( response.response.message )
                    }
                }
            })
            .catch( error => {
                setLoading( false )
                console.log( error )
            } )

    }, [company, email] )

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        return !isLoading && isValidEmail && isValidCompany
    }, [isValidCompany, isValidEmail, loading] )


    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText isError={true} isHidden={false}>
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    return (
        <React.Fragment>
            <ModalResetPasswordSuccess message={ successMessage } />
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <Form>
                        { helperText }
                        <DiazarFormInput getState={
                                            (isValid, value) => {
                                                setIsValidEmail( isValid )
                                                setEmail( value )
                                            }
                                        }
                                         id="password-reset-request-email"
                                         label='Email'
                                         isRequired
                                         helperText="Introduce el email registrado en diazar por la empresa donde quieres recuperar la contraseña"
                                         placeholder="Email de Usuario"
                                         type="email"
                        />

                        <DiazarFormInput getState={
                                            (isValid, value) => {
                                                setIsValidCompany( isValid )
                                                setCompany( value )
                                            }
                                        }
                                         id="password-reset-request-company"
                                         label='Empresa'
                                         isRequired
                                         placeholder="Empresa de Usuario"
                                         type="text"
                        />

                        <ActionGroup>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); handleLogin() }}
                                    isBlock
                                    isDisabled={ !isValidForm }
                                    isLoading={ ( loading !== undefined ) ? loading : false }
                            >
                                Enviar
                            </Button>
                        </ActionGroup>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );
}

/*
const [isRememberMeChecked, setIsRememberMeChecked] = useState<boolean>(false)
<FormGroup fieldId="pf-login-remember-me-id">
    <Checkbox
        id="pf-login-remember-me-id"
        label="Recordarme"
        isChecked={isRememberMeChecked}
        onChange={ (curremt, e) => { setIsRememberMeChecked(curremt) } }
    />
</FormGroup>
*/