import React from 'react';
import '@patternfly/react-core/dist/styles/base.css'
import './App.css';
import './change-colors.css';
import {
    BrowserRouter as Router,
    Routes, Route, Navigate
} from 'react-router-dom';

import {routes} from './Config/routes'
import {AuthProvider, useAuthState} from "./Context/AuthProvider/AuthProvider";
import Moment from "react-moment";
import 'moment/locale/es';
import {AlertProvider} from "./Context/AlertProvider/AlertProvider";
import {ClientContextProvider} from "react-fetching-library";
import {Client} from "./Config/Client";

Moment.globalLocale = 'es';

const AppRoutes:React.FunctionComponent = (_props) => {
    const user = useAuthState()

    return (
        <Router>
            <Routes>
                { routes.map( (props ) =>
                    <Route
                        key={props.path}
                        path={props.path}
                        element={( !props.isPrivate || Boolean(user.token) ) ? <props.component /> : <Navigate to="/login" replace />}
                    />
                ) }
            </Routes>
        </Router>
    )
}

export const App: React.FunctionComponent = (_props) => {
    React.useEffect( ()=>{
        const el = document.querySelector(".loader-container");
        if (el) {
            el.remove();
        }
    }, [] )

    return (
      <AuthProvider>
          <AlertProvider>
              <ClientContextProvider client={Client}>
              <AppRoutes />
              </ClientContextProvider>
          </AlertProvider>
      </AuthProvider>
    );
}
