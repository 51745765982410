import React from "react";
import {useParams} from "react-router-dom";
import {
    EmptyState,
    EmptyStateIcon,
    Title,
    EmptyStateBody,
    Bullseye, Button
} from "@patternfly/react-core";
import {SyncIcon, ErrorCircleOIcon} from '@patternfly/react-icons/dist/esm/icons';
import {useParameterizedQuery} from "react-fetching-library";
import {resolveAction} from "../../Actions/MailActions/MailActionsActions";
import {useAuthDispatch} from "../../Context/AuthProvider/AuthProvider";
import {logout} from "../../Context/AuthProvider/AuthProviderActions";
export const AprobarJWTAction : React.FunctionComponent = (_props) => {
    const params = useParams();
    const [hasError, setHasError] = React.useState<boolean>(false)
    const [refresh, setRefresh] = React.useState<boolean>(true)

    const [message, setMessage] = React.useState<string>("")
    const [title, setTitle] = React.useState<string>("")

    // @ts-ignore
    const resolve = useParameterizedQuery( resolveAction )

    const dispatch = useAuthDispatch()

    const icon = React.useMemo( () => {
        if ( hasError ){
            return ErrorCircleOIcon
        }
        return  SyncIcon
    }, [hasError] )

    const logoutFunc = React.useCallback( () => {
        logout( dispatch ).then()
    }, [dispatch] )

    React.useEffect( () => {
        if( params.tokenJWT === undefined || params.tokenJWT === "" ) {
            setHasError(true)
            setTitle("URL dañada")
            setMessage("La URL que usted intenta acceder esta dañada, intente de nuevo mas tarde.")
        }else{
            if( refresh ){
                setRefresh(false)

                setHasError(false)
                setTitle("Validando información")
                setMessage("Estamos conectando con el servidor, esta accion puede demorar unos segundos.")

                resolve.query( params.tokenJWT ).then( (response) => {
                    if( response.payload.error["has-error"] ){
                        setHasError(true)
                        setTitle("Error al validar")
                        setMessage(response.payload.error.error)

                    }else{
                        setHasError(false)
                        setTitle("Validación exitosa")
                        setMessage("La información ha sido validada correctamente, puede cerrar esta ventana.")
                    }

                } )
            }

        }
    }, [params, refresh, resolve] )

    return (
        <Bullseye>
            <EmptyState>
                <EmptyStateIcon icon={ icon } />
                <Title headingLevel="h4" size="lg">
                    { title }
                </Title>
                <EmptyStateBody>
                    { message }
                </EmptyStateBody>
                <Button variant="primary" onClick={ logoutFunc }>Cerrar Sesión</Button>
            </EmptyState>
        </Bullseye>
    )
}
