import React from "react";
import {LoginLayout} from "../../Layouts/LoginLayout";
import {DiazarPasswordResetForm} from "./DiazarPasswordResetForm";

export const PasswordResetRequest: React.FunctionComponent = (_props) => {
    const textContent = React.useMemo( () => {
        const text = 'Es nesario que cuente con acceso al correo electrónico y ' +
            'al identificador de la empresa donde desea reestablecer su contraseña.'

        return text
    }, [])

    const subtitleContent = React.useMemo( ()=>{
        return 'Si usted tiene problemas para iniciar sesión en los sistemas Diazar, puede restablecer ' +
            'reestableser su contraseña.'
    }, [] )

    return (
        <React.Fragment>

            <LoginLayout loginTitle="Reestablecer Contraseña"
                         loginSubtitle={ subtitleContent }
                         textContent={textContent}
            >
                <DiazarPasswordResetForm />
            </LoginLayout>

        </React.Fragment>
    );
}