import {ServicesUrl} from "../../Config/UrlRuotes";
import {GetToken} from "../Domain";

export const getZendeskToken = () => ({
    method: 'GET',
    endpoint: ServicesUrl.zendesk,
    headers: {
        "Authorization-Token": GetToken()
    }
});

export const getEmailValidationCode = (data:{email: string}) => ({
    method: 'POST',
    endpoint: ServicesUrl.zendesk + '/mail/code',
    body: data,
    headers: {
        "Authorization-Token": GetToken()
    }
});

export const sendEmailValidationCode = (data:{email: string, code:string}) => ({
    method: 'POST',
    endpoint: ServicesUrl.zendesk + '/mail/validate',
    body: data,
    headers: {
        "Authorization-Token": GetToken()
    }
});
