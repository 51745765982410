import React from "react";
import {InfoLayout} from "../../Layouts/InfoLayout";

export const PrivacyPolicies: React.FunctionComponent = (_props) => {
    const HTML = (
        <div>
            <p>La Política de Privacidad de <strong>Soluciones Integrales y Tecnología Empresarial del Mayab S.A. de
                C.V. (“Diazar”)</strong> tiene el propósito de explicar cómo se recopila, utiliza y protege su
                información. Al utilizar cualquiera de los sitios web o servicios de Diazar usted da su consentimiento
                para la recopilación y uso de su información personal como se describe en esta Política de Privacidad.
                Esta Política de Privacidad puede ser actualizada de vez en cuando y publicaremos la versión más
                reciente en nuestro sitio web: Diazar.com.</p>
            <p><strong>¿Para qué utilizaremos sus datos personales?</strong><br /> Los datos personales que recabamos de
                usted, los utilizaremos para las siguientes finalidades, necesarias para el proporcionar el servicio que
                solicita:</p>
            <ul>
                <li>Para verificar y confirmar su identidad.&nbsp;</li>
                <li>Para poder proceder al registro de sus datos como cliente de Diazar.&nbsp;</li>
                <li>Respaldos de información.</li>
                <li>Aviso de vencimiento de contratos de licencia.</li>
            </ul>
            <p>&nbsp;De manera adicional, utilizaremos su información personal para las siguientes finalidades que no
                son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor
                atención:</p>
            <ul>
                <li>Análisis para uso interno y elaboración de reportes estadísticos.&nbsp;</li>
                <li>Control de expedientes de clientes, proveedores y prestadores de servicios.&nbsp;</li>
                <li>Prospección comercial.</li>
                <li>Proporcionar información sobre nuevas versiones.</li>
                <li>Para poder ofrecerle precios especiales en nuestros productos y servicios.&nbsp;</li>
            </ul>
            <p>En caso de que no desee que sus datos personales se utilicen para estos fines, indíquelo a
                continuación:</p>
            <p>No consiento que mis datos personales se utilicen para los siguientes fines:</p>
            <ul >
                <li>Análisis para uso interno y elaboración de reportes estadísticos.&nbsp;</li>
                <li>Control de expedientes de clientes, proveedores y prestadores de servicios.&nbsp;</li>
                <li>Prospección comercial.</li>
                <li>Proporcionar información sobre nuevas versiones.</li>
                <li>Para poder ofrecerle precios especiales en nuestros productos y servicios.&nbsp;</li>
            </ul>
            <p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le
                neguemos los servicios y productos que solicita o contrata con nosotros.</p>
            <p><strong>¿Qué datos personales recabamos para estos fines?</strong></p>
            <p>Para llevar a cabo las finalidades descritas en el presente Aviso de privacidad, utilizaremos los
                siguientes datos personales:</p>
            <ul>
                <li>Nombre completo o razón social</li>
                <li>Correo electrónico</li>
                <li>Domicilio</li>
                <li>Teléfono</li>
                <li>RFC</li>
                <li>Giro o actividad empresarial</li>
                <li>Puesto o cargo que desempeña</li>
            </ul>
            <p><strong>¿Con quién compartimos su información y para qué fines?</strong></p>
            <p>Le informamos que sus datos personales son compartidos dentro y fuera del país con las siguientes
                personas, empresas, organizaciones y autoridades distintas a nosotros para los siguientes fines:</p>
            <table className="uk-table">
                <thead>
                <tr>
                    <th>Destinatario de datos</th>
                    <th>Finalidad</th>
                    <th>Requiere consentimiento</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Google Inc.</td>
                    <td>Recopilación estadística del uso y tráfico de la página</td>
                    <td>No</td>
                </tr>
                <tr>
                    <td>Mail Chimp</td>
                    <td>Resguardo de base de datos de clientes y prospectos</td>
                    <td>Si</td>
                </tr>
                <tr>
                    <td>Proveedor autorizado de certificación (PACs)</td>
                    <td>Timbrado de CFDI</td>
                    <td>No</td>
                </tr>
                </tbody>
            </table>
            <p><strong>¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?</strong>
            </p>
            <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y
                las&nbsp;condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de
                su información&nbsp;personal en caso de que esté desactualizada, sea inexacta o incompleta
                (Rectificación); que la eliminemos de&nbsp;nuestros registros o bases de datos cuando considere que la
                misma no está siendo utilizada conforme a los&nbsp;principios, deberes y obligaciones previstas en la
                normativa (Cancelación); así como oponerse al uso de sus&nbsp;datos personales para fines específicos
                (Oposición). Estos derechos se conocen como derechos ARCO.</p>
            <p>Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo
                siguiente:</p>
            <p>a) ¿A través de qué medios pueden acreditar su identidad el titular o representante legal?</p>
            <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva en
                el&nbsp;correo:&nbsp;<span id="cloak87c56047fc3b21a6f43a93491f36bd96"><a
                    href="mailto:contacto@diazar.com">contacto@diazar.com</a></span>
                &nbsp;</p>
            <p>b) ¿Qué información y/o documentación deberá tener la solicitud?</p>
            <p>Identificación oficial y/o copia de acta constitutiva (para personas morales).</p>
            <p>c) ¿Por qué medio le comunicaremos la respuesta a la solicitud?</p>
            <p>Vía correo electrónico</p>
            <p>d) ¿En cuántos dias le daremos respuesta a su solicitud?</p>
            <p>En un plazo no mayor a 30 días naturales.</p>
            <p><strong>¿Cómo puede revocar su consentimiento para el uso de sus datos personales?</strong><br /> Usted
                puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus
                datos&nbsp;personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos
                atender su&nbsp;solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna
                obligación legal requiramos&nbsp;seguir tratando sus datos personales. Asimismo, usted deberá considerar
                que para ciertos fines, la revocación&nbsp;de su consentimiento implicará que no le podamos seguir
                prestando el servicio que nos solicitó, o la conclusión&nbsp;de su relación con nosotros.</p>
            <p>Para revocar su consentimiento deberá presentar su solicitud a: <span
                id="cloakfbc4f39477b2f5e194621e7bb1d960fd"><a href="mailto:contacto@diazar.com">contacto@diazar.com</a></span>
            </p>
            <p>Para conocer el procedimiento y requisitos para la revocación del consentimiento, usted podrá llamar
                a los siguientes número telefónico:</p>
            <ul>
                <li><strong>Ventas</strong>: (999) 399-03-78</li>
                <li><strong>Asistencia Técnica (ACYST)</strong>: (999) 399-04-67</li>
            </ul>
            <p><strong>¿Cómo puede limitar el uso o divulgación de su información personal?</strong></p>
            <p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos
                los&nbsp;siguientes medios:</p>
            <p>Enviar un correo a la dirección <span id="cloakb9893076dde2c69f2ab6a5d9fc8ebfbb"><a
                href="mailto:contacto@diazar.com">contacto@diazar.com</a></span>
            </p>
            <p><strong>¿Cómo puede conocer los cambios a este aviso de privacidad?</strong><br /> El presente aviso de
                privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de
                nuevos&nbsp;requerimientos legales; de nuestras propias necesidades por los productos o servicios que
                ofrecemos; de&nbsp;nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por
                otras causas.</p>
            <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso
                de&nbsp;privacidad, a través de nuestra página de Internet www.diazar.com o bien mediante un correo
                que&nbsp;le haremos llegar a su cuenta de e-mail registrada.</p>
            <p>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por
                alguna&nbsp;conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones
                previstas en la Ley federal&nbsp;de Protección de Datos Personales en Posesión de los Particulares, su
                Reglamento y demás ordenamientos&nbsp;aplicables, podrá interponer su inconformidad o denuncia ante el
                Instituto Federal de Acceso a la Información y&nbsp;Protección de Datos (IFAI).</p>
            <p>Otorgo mi consentimiento para que mis datos personales sean tratados conforme a lo señalado en
                el&nbsp;presente Aviso de privacidad.</p>

            </div>
    )

    return (
        <InfoLayout title="Politicas de Privacidad">
            { HTML }
        </InfoLayout>
    )
}
