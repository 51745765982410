import React, {useState} from "react";
import {PasswordResetPayload, ServicesUrl, UserToResetPass} from "../../Config/UrlRuotes";
import {ActionGroup, Button, Form, FormHelperText} from "@patternfly/react-core";
import {ExclamationCircleIcon} from "@patternfly/react-icons";
import {DiazarFormInput} from "../../Components/Form/DiazarFormInput";
import {ModalResetPasswordSuccess} from "../PasswordResetRequest/ModalResetPasswordSuccess";

interface ViewProps {
    userData: UserToResetPass
}

export const DiazarRestorePassForm : React.FunctionComponent<ViewProps> = (props) => {
    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)

    const [password, setPassword] = useState<string>("")
    const [isValidPassword, setIsValidPassword] = useState<boolean>(false)

    const [isValidPasswordConfirm, setIsValidPasswordConfirm] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string|undefined>(undefined)

    const { userData } = props

    const handleForm = React.useCallback( async ()=>{

        const data = { ...userData, password }

        setLoading( true )

        await fetch( ServicesUrl.reset_pass, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        } )
            .then(response => response.json())
            .then( (data)=>{
                setLoading( false )

                const response:PasswordResetPayload = data
                if( response.error["has-error"] ){
                    setErrorMessage( response.error.message )
                }else{
                    setErrorMessage( undefined )
                    if( response.response.message ){
                        setSuccessMessage( response.response.message )
                    }
                }

            })
            .catch( error => {
                setLoading( false )
                console.log( error )
            } )

    }, [userData, password] )


    const helperText = React.useMemo( () => {
        if ( errorMessage === undefined ){
            return null
        }else{
            return (
                <FormHelperText isError={true} isHidden={false}>
                    <ExclamationCircleIcon />
                    &nbsp;{errorMessage}.
                </FormHelperText>
            )
        }
    }, [errorMessage] )

    const isValidForm = React.useMemo<boolean>( () => {
        const isLoading = ( loading !== undefined ) ? loading : false
        return !isLoading && isValidPassword && isValidPasswordConfirm
    }, [loading, isValidPassword, isValidPasswordConfirm] )

    return (
        <React.Fragment>
            <ModalResetPasswordSuccess message={ successMessage } />
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <Form >
                        { helperText }
                        <DiazarFormInput id="password" label="Contraseña" isRequired
                                         type="password"
                                         getState={ (isValid, value) => {
                                             setIsValidPassword( isValid )
                                             setPassword( value )
                                         } }
                                         min={8}
                                         max={12}
                                         helperText="Mínimo 8 caracteres y máximo 12 caracteres"
                        />

                        <DiazarFormInput id="password-repeat" label="Repetir Contraseña" isRequired
                                         type="password"
                                         getState={ (isValid, _value) => {
                                             setIsValidPasswordConfirm( isValid )
                                         } }
                                         isEqualTo={ password }
                        />


                        <ActionGroup>
                            <Button type="submit"
                                    onClick={ e => { e.preventDefault(); handleForm() }}
                                    isBlock
                                    isDisabled={ !isValidForm }
                                    isLoading={ ( loading !== undefined ) ? loading : false }
                            >
                                Enviar
                            </Button>
                        </ActionGroup>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}
