import React from "react";
import {LoginLayout} from "../../Layouts/LoginLayout";
import {LoginForm} from "./LoginForm";
import {useAuthState} from "../../Context/AuthProvider/AuthProvider";
import {InfoLayout} from "../../Layouts/InfoLayout";
import {AprobarJWTAction} from "./AprobarJWTAction";

export const AprobarJWT : React.FunctionComponent = (_props) => {
    const {token} = useAuthState()
    const [hasLogin, setHasLogin] = React.useState<boolean>(false)

    React.useEffect( () => {
        setHasLogin ( token !== undefined && token !== "")
    }, [token] );

    if ( hasLogin ){
        return (
            <InfoLayout>
                <AprobarJWTAction />
            </InfoLayout>
        )
    }

    return (
        <LoginLayout loginTitle="Login Requerido"
                        loginSubtitle="Es necesario iniciar sesión para poder continuar."
        >
            <LoginForm />
        </LoginLayout>
    )
}
