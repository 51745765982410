export interface BasicResponse{
    status: "SUCCESS" | "ERROR",
    error: {
        "has-error": boolean,
        error?: string
    },
    response: any
}

export const GetToken = () => {
    const token:string = localStorage.getItem("currentUser")
        ? JSON.parse( localStorage.getItem("currentUser") + "" ).token
        : "PTM";

    return token
}
