import React, {useReducer, ReactNode} from "react";
import {AuthReducer, initialState, LoginPayload} from "./AuthProviderReducer";
import {LoginDispacher} from "./AuthProviderActions";

const AuthStateContext = React.createContext<LoginPayload>( {} );
const AuthDispatchContext = React.createContext< React.Dispatch<LoginDispacher> >(() => null );


export function useAuthState() {
    const context = React.useContext(AuthStateContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within a AuthProvider");
    }

    return context;
}

export function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext);
    if (context === undefined) {
        throw new Error("useAuthDispatch must be used within a AuthProvider");
    }

    return context;
}

interface ViewProps {
    children?: ReactNode | undefined
}

export const AuthProvider: React.FunctionComponent<ViewProps> = (props) => {
    const [user, dispatch] = useReducer(AuthReducer, initialState)

    return (
        <AuthStateContext.Provider value={user}>
            <AuthDispatchContext.Provider value={ dispatch }>
                { props.children }
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}